<template>
    <div id="container">
      <div id="inviteContainer">
        <div class="logoContainer">
          <img class="text" src="@/assets/images/logo-light.png" alt="Logo">
        </div>
        <div class="acceptContainer" :class="{ 'loadIn': acceptContainerLoaded }">
          <div >
            <form @submit.prevent="">
                
              <h1>please select the company you want to manage!</h1>
              <label for="input-societe" style="color: aliceblue;" class="d-block mt-4">Company List</label>
              <multiselect :max-height="80" v-model="name"  id="input-societe"
                :options="selectedNames" @input="setsociete()" />
                <button @click="select" type="button" class="btn" style="background-color: rgba(3, 4, 94, 0.8); color: white;">
                Continue
                </button>
                <button @click="logout" type="button" class="btn ml-1" style="background-color: rgba(117, 6, 6, 0.8); color: white;">
                Logout
                </button>
                


              
            </form>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required, email } from "vuelidate/lib/validators";
  import axios from "axios"
  
  import swal from "sweetalert"
  import Multiselect from "vue-multiselect";
  export default {
    data() {
      return {
        name: "",
        selectedNames: [],
        acceptContainerLoaded: false,
        matricule: "",
        
        
      }
    },
    validations: {
      user: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    },
    methods: {
      logout(){
        localStorage.clear()
        this.$router.push({ path: "/" })
      },
      getscociete(){
          axios.get('https://finex.4help.tn/api/v1/societes').then(res => { console.log(res.data); res.data.map(obj => this.selectedNames.push(obj.Name) )})
      },
      setsociete(){
          localStorage.setItem('societe', JSON.stringify(this.name))
          axios.get(`https://finex.4help.tn/api/v1/societes/${this.name}`).then(res => { localStorage.setItem('matricule', res.data["Matricule"]) })

          
      },
      select(){
        if (this.name == null || this.name == "") {
          swal({
            title: "Error!",
            text: "Please select a company!",
            icon: "error",
          })
        }else{
          this.$router.push({ path: "/" })
        }
        
      },

      
      
      showSignIn() {
        this.signupForm = false
        $(function () {
          $(".formDiv, form h1").addClass("loadIn");
  
        });
      }
    },
    computed: {
      notification() {
        return this.$store ? this.$store.state.notification : null;
      },
      notificationAutoCloseDuration() {
        return this.$store && this.$store.state.notification ? 5 : 0;
      },
    },
    components: {
      Multiselect
    },
    mounted() {
        this.getscociete();
      // JQUERY
      $(function () {
        var images = [
          "/images/login-bg.jpg",
          "/images/login-bg.jpg"
        ];
  
        $("#container").append(
          "<style>#container, .acceptContainer:before, #logoContainer:before {background: url(" +
          images[Math.floor(Math.random() * images.length)] +
          ") center fixed }"
        );
  
        setTimeout(function () {
          $(".logoContainer").transition({ scale: 1 }, 300, "ease");
          setTimeout(function () {
            $(".logoContainer .logo").addClass("loadIn");
            setTimeout(function () {
              $(".logoContainer .text").addClass("loadIn");
              setTimeout(function () {
                $(".acceptContainer").transition({ height: "100%" });
                setTimeout(function () {
                  $(".acceptContainer").addClass("loadIn");
                  setTimeout(function () {
                    $(".formDiv, form h1").addClass("loadIn");
                  }, 200);
                }, 500);
              }, 500);
            }, 500);
          }, 1000);
        }, 10);
      });
  
    },
  }
  
  </script>
  
  <style>
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900");
  
  * {
    outline-width: 0;
    font-family: "Montserrat" !important;
  }
  
  body {
    background: #23272A;
  }
  
  #container {
    height: 100vh;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #inviteContainer {
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
  }
  
  #inviteContainer .acceptContainer {
    padding: 45px 30px;
    box-sizing: border-box;
    width: 400px;
    margin-left: -400px;
    overflow: hidden;
    height: 0;
    opacity: 0;
  }
  
  #inviteContainer .acceptContainer.loadIn {
    opacity: 1;
    margin-left: 0;
    transition: 0.5s ease;
  }
  
  #inviteContainer .acceptContainer:before {
    content: "";
    background-size: cover !important;
    box-shadow: inset 0 0 0 3000px rgba(3, 4, 94, 0.8);
    filter: blur(10px);
    position: absolute;
    width: 150%;
    height: 150%;
    top: -50px;
    left: -50px;
  }
  
  form {
    position: relative;
    text-align: center;
    height: 100%;
  }
  
  form h1 {
    margin: 0 0 15px 0;
    font-family: "Work Sans" !important;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0;
    left: -30px;
    position: relative;
    transition: 0.5s ease;
  }
  
  form h1.loadIn {
    left: 0;
    opacity: 1;
  }
  
  .formContainer {
    text-align: left;
  }
  
  .formContainer .formDiv {
    margin-bottom: 30px;
    left: -25px;
    opacity: 0;
    transition: 0.5s ease;
    position: relative;
  }
  
  .formContainer .formDiv.loadIn {
    opacity: 1;
    left: 0;
  }
  
  .formContainer .formDiv:last-child {
    padding-top: 10px;
    margin-bottom: 0;
  }
  
  .formContainer p {
    margin: 0;
    font-weight: 700;
    color: #aaa;
    font-size: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .formContainer input[type=password],
  .formContainer input[type=email] {
    background: transparent;
    border: none;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
    padding: 15px 0;
    box-sizing: border-box;
    color: #fff;
    width: 100%;
  }
  
  .formContainer input[type=password]:focus,
  .formContainer input[type=email]:focus {
    box-shadow: inset 0 -2px 0 #fff;
  }
  
  .logoContainer {
    padding: 45px 35px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0, 0);
  }
  
  .logoContainer img {
    width: 150px;
    margin-bottom: -5px;
    display: block;
    position: relative;
  }
  
  .logoContainer img:first-child {
    width: 150px;
  }
  
  .logoContainer .text {
    padding: 25px 0 10px 0;
    margin-top: -70px;
    opacity: 0;
  }
  
  .logoContainer .text.loadIn {
    margin-top: 0;
    opacity: 1;
    transition: 0.8s ease;
  }
  
  .logoContainer .logo {
    width: 50px;
    position: relative;
    top: -20px;
    opacity: 0;
  }
  
  .logoContainer .logo.loadIn {
    top: 0;
    opacity: 1;
    transition: 0.8s ease;
  }
  
  .logoContainer:before {
    content: "";
    background-size: cover !important;
    position: absolute;
    top: -50px;
    left: -50px;
    width: 150%;
    height: 150%;
    filter: blur(10px);
    box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.8);
  }
  
  .forgotPas {
    color: #aaa;
    opacity: 0.8;
    text-decoration: none;
    font-weight: 700;
    font-size: 10px;
    margin-top: 15px;
    display: block;
    transition: 0.2s ease;
  }
  
  .forgotPas:hover {
    opacity: 1;
    color: #fff;
  }
  
  .acceptBtn {
    width: 100%;
    box-sizing: border-box;
    background: #1c2c38;
    border: none;
    color: #fff;
    padding: 20px 0;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .acceptBtn:hover {
    background: #294052e8;
  }
  
  .register {
    color: #aaa;
    font-size: 12px;
    padding-top: 15px;
    display: block;
  }
  
  .register a {
    color: #fff;
    text-decoration: none;
    margin-left: 5px;
    box-shadow: inset 0 -2px 0 transparent;
    padding-bottom: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .register a:hover {
    box-shadow: inset 0 -2px 0 #fff;
  }
  </style>